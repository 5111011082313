"use client";

import { Fragment, useEffect } from "react";

import { useCustomGate } from "shared-lib/helpers/customStatSigHooks";
import {
  ezoicCallForAds,
  ezoicDefinePlaceholders,
  ezoicDisplayAds,
  ezoicRefreshAds,
} from "shared-lib/helpers/ezoicFunctions";
import { log } from "shared-lib/helpers/logging";
import { isMobile } from "shared-lib/helpers/utilityFunctions";

import { GlobalActionTypes, useGlobalState } from "@/context/globalState";
import { PAGE_NAMES } from "@/lib/constants";

const ID_TO_BE_REMOVED = 116;

type Props = {
  placeholders: number[];
  mobileOnly: number[];
  desktopOnly: number[];
  dynamicContentDelay?: number;
};

export default function EzoicHandler({
  placeholders,
  mobileOnly,
  desktopOnly,
  dynamicContentDelay = 0,
}: Props) {
  const ezoicGate = useCustomGate("ezoic_ads");
  const isEzoicVidePlayer = useCustomGate("ezoic_video_player");
  const [globalState, dispatch] = useGlobalState();

  useEffect(() => {
    if (ezoicGate) {
      log(">>> use Ezoic");
      const listOfPlaceholders = placeholders;
      if (isMobile()) {
        listOfPlaceholders.push(...mobileOnly);
      } else {
        listOfPlaceholders.push(...desktopOnly);
      }

      dispatch({
        type: GlobalActionTypes.SET_DEFINED_PLACEHOLDERS,
        payload: Array.from(new Set(listOfPlaceholders)),
      });

      if (isEzoicVidePlayer) {
        const indexOfId = listOfPlaceholders.indexOf(ID_TO_BE_REMOVED);
        if (indexOfId > -1) {
          listOfPlaceholders.splice(indexOfId, 1);
        }
      }

      if (
        globalState.navigation.visitedPages.includes(PAGE_NAMES.ARTICLE) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.TAG) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.RECIPE) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.CATEGORY) ||
        globalState.navigation.visitedPages.includes(PAGE_NAMES.HOME)
      ) {
        const placeholdersToBeDefined = listOfPlaceholders.filter(
          (placeholderId) =>
            !globalState.definedPlaceholders.includes(placeholderId),
        );

        ezoicDefinePlaceholders(
          Array.from(
            new Set(placeholdersToBeDefined.concat(listOfPlaceholders)),
          ).sort(),
        );

        setTimeout(() => {
          log(">>> Ezoic refresh");
          ezoicRefreshAds();
        }, 250);
      } else {
        setTimeout(() => {
          log(">>> Ezoic define");
          ezoicDefinePlaceholders(listOfPlaceholders.sort());
          ezoicCallForAds();
          ezoicDisplayAds();
        }, dynamicContentDelay)
      }
    }
    // TODO: determine if this can / should be exhaustive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment key="ezoic-handler" />;
}
