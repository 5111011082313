"use client";

import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { useCustomGate } from "shared-lib/helpers/customStatSigHooks";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";
import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import EzoicHandler from "@/components/EzoicHandler";
import RecipeThumb from "@/components/RecipeThumb";
import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import SquareRecipeCard from "@/components/SquareRecipeCard";
import Ad from "@/components/ad";
import HugeWideRecipeCard from "@/components/brand/CookingProfessionally/components/hugeWideRecipeCard";
import SmallRecipeCard from "@/components/brand/CookingProfessionally/components/smallRecipeCard";
import Divider from "@/components/divider";
import {
  EZOIC_HOME_PAGE_PLACEHOLDERS,
  EZOIC_HOME_PAGE_PLACEHOLDERS_DESKTOP_ONLY,
  EZOIC_HOME_PAGE_PLACEHOLDERS_MOBILE_ONLY,
} from "@/lib/constants";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";

function BrandedSection({
  className = "",
  white = false,
  name = "",
  id = "",
}: {
  className?: string;
  white?: boolean;
  name?: string;
  id?: string;
}) {
  return (
    <SectionHeader
      id={id}
      name={name}
      className={clsx(
        className,
        white ? "text-white" : "text-theme-primary",
        "text-center font-serif text-2xl print:hidden",
      )}
    />
  );
}

function SideBySideRecipe({ recipe }: { recipe: Recipe }) {
  return (
    <Link href={getRecipeLink(recipe)} className="group flex">
      <RecipeThumb recipe={recipe} zoom className="w-1/2 max-w-[100px]" />
      <div className="w-1/2 bg-gray-100 p-2 pt-0">
        <div className="text-sm uppercase tracking-widest text-theme-gray-300">
          {recipe.category?.name}
        </div>
        <div className="w-full pt-1 font-serif text-base font-bold leading-6 decoration-2 transition-all duration-300 group-hover:underline">
          <SymbolizedElement title={recipe.title} />
        </div>
      </div>
    </Link>
  );
}

export default function CookingProfessionallyHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const firstRecipeCardRecipe = allRecipes[0];
  const recipesHeroSection = allRecipes.slice(1, 6);
  const recipesOfTheWeek = allRecipes.slice(5, 8);
  const whatsNewRecipes = allRecipes.slice(8, 14);
  const bigRecipe = allRecipes[14];
  const trendingRecipes = allRecipes.slice(15, 23);
  const ezoicGate = useCustomGate("ezoic_ads");

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[260px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
          ezoicId="140"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
          ezoicId="140"
          screen="MOBILE"
        />

        {firstRecipeCardRecipe && (
          <Section className="bg-gray-100 py-8">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-6">
              <div className="col-span-1 md:col-span-4">
                <SquareRecipeCard
                  recipe={firstRecipeCardRecipe}
                  imageSize={"large"}
                  showDescription
                  size="large"
                  zoom={false}
                  titleFontFace="font-sans font-normal"
                  titleColor="text-black"
                  boxStyle="bg-gray-100"
                  priority
                  onClick={() => {
                    gtag.event({
                      action: TRACKING_EVENTS.recipe_tile_click,
                      category: TRACKING_CATEGORIES.navigation,
                      label: "main_hero_recipe",
                    });

                    logStatsig(TRACKING_EVENTS.recipe_tile_click, {
                      index: "main_hero_recipe",
                    });

                    ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
                      index: "main_hero_recipe",
                    });
                  }}
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <div className="grid grid-cols-2 gap-8 md:grid-cols-1">
                  {recipesHeroSection.map((x, i) => {
                    return <SideBySideRecipe key={i} recipe={x} />;
                  })}
                </div>
              </div>
            </div>
          </Section>
        )}

        {ezoicGate && (
          <Ad
            id="ezoic_141"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="141"
          />
        )}

        <Section className="py-8">
          <BrandedSection name="Recipes of the Week" id="recipes-of-the-week" />
          <Divider className={"mt-4 border-theme-primary"} />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-col-1 md:grid-cols-3"
            recipes={recipesOfTheWeek}
            trackingName="home-page"
            cardTitleFontFace="font-serif"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
          ezoicId="142"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
          ezoicId="142"
          screen="MOBILE"
        />

        <Section className="bg-gray-100 py-8">
          <BrandedSection name="What's New" />
          <Divider className={"relative !mt-4 border-theme-primary"} />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
          />
        </Section>

        {ezoicGate && (
          <Ad
            id="ezoic_143"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="143"
          />
        )}

        <Section fullWidth className="mb-8">
          {bigRecipe && (
            <>
              <HugeWideRecipeCard
                className="hidden md:block"
                recipe={bigRecipe}
              />
              <SmallRecipeCard className="md:hidden" recipe={bigRecipe} />
            </>
          )}
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
          ezoicId="144"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
          ezoicId="144"
          screen="MOBILE"
        />

        <Section className="py-8">
          <BrandedSection name="Trending" />
          <Divider className={"!mt-2 border-theme-primary"} />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={trendingRecipes}
            trackingName="home-page"
            cardTitleFontFace="font-serif"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        {ezoicGate && (
          <Ad
            id="ezoic_146"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="146"
          />
        )}

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>

      <EzoicHandler
        placeholders={EZOIC_HOME_PAGE_PLACEHOLDERS}
        mobileOnly={EZOIC_HOME_PAGE_PLACEHOLDERS_MOBILE_ONLY}
        desktopOnly={EZOIC_HOME_PAGE_PLACEHOLDERS_DESKTOP_ONLY}
      />
    </div>
  );
}
