"use client";
import { useEffect } from "react";

import clsx from "clsx";
import { useInView } from "react-intersection-observer";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { isActiveExperiment } from "shared-lib/helpers/customStatSigHooks";
import { log } from "shared-lib/helpers/logging";
import {
  logStatsig,
  TRACKING_CATEGORIES,
  TRACKING_EVENTS,
} from "shared-lib/helpers/statsigFunctions";

interface iCustomOverrides {
  url: string;
}

type Props = {
  viewedItem: string;
  threshold?: number;
  rootMargin?: string;
  triggerOnce?: boolean;
  className?: string;
  customTracking?: () => void;
  customTrackingEvent?: keyof TRACKING_EVENTS;
  elementId?: string;
  listOfElementsTracked?: string[];
  customOverrides?: iCustomOverrides;
};

export default function DepthTracker({
  rootMargin = "-15px",
  threshold = 0.3,
  triggerOnce = true,
  className = "",
  viewedItem,
  customTracking = () => {
    return;
  },
  customTrackingEvent = TRACKING_EVENTS.scroll_depth,
  elementId = "",
  listOfElementsTracked = [],
  customOverrides = {} as iCustomOverrides,
}: Props) {
  const { inView: isVisible, ref: theRef } = useInView({
    rootMargin: rootMargin,
    threshold: threshold,
    triggerOnce: triggerOnce,
  });

  useEffect(() => {
    if (elementId && listOfElementsTracked.indexOf(elementId) > -1) {
      return;
    }

    if (isVisible) {
      listOfElementsTracked.push(elementId);

      log("tracked: " + viewedItem);
      ClickServer.track(
        customTrackingEvent,
        {
          viewed_item: viewedItem,
        },
        customOverrides,
      );

      if (isActiveExperiment()) {
        logStatsig(customTrackingEvent, {
          viewed_item: viewedItem,
        });
      }

      gtag.event({
        action: customTrackingEvent,
        category: TRACKING_CATEGORIES.view,
        label: viewedItem,
      });

      customTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, elementId]);

  return (
    <span
      id={elementId}
      className={clsx(className, "depth-tracker")}
      ref={theRef}
    />
  );
}
