"use client";
import { useEffect, useState } from "react";

import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { useCustomGate } from "shared-lib/helpers/customStatSigHooks";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import FullWidthElement from "shared-components/components/fullWidthElement";

import InsetRecipeCard from "@/components/InsetRecipeCard";
import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import SquareRecipeCard from "@/components/SquareRecipeCard";
import Ad from "@/components/ad";

function BrandedSection({
  className = "",
  name,
}: {
  className?: string;
  name: string;
}) {
  return (
    <SectionHeader
      name={name}
      className={clsx(
        className,
        "mb-8 w-full border-b-4 border-theme-primary pt-8 uppercase print:hidden",
      )}
    />
  );
}

export default function RecipeSaverHomepage({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const recipes = mainRecipes.concat(supplementalRecipes);
  const [gridID, setGridID] = useState("");

  const ezoicGate = useCustomGate("ezoic_ads");

  useEffect(() => {
    setGridID(uuidv4());
  }, []);

  if (!mainRecipes.length && !supplementalRecipes.length) {
    return null;
  }

  const gridItemClicked = (index: number) => {
    const clickTrackObj = {
      sub_type: "grid_click",
      grid_id: gridID, // foreign key - connecting to unique grid view event
      tile_index: index,
    };

    console.log("grid click should fire", { clickTrackObj });
  };

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[260px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
          ezoicId="140"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
          ezoicId="140"
          screen="MOBILE"
        />

        <Section className="py-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <InsetRecipeCard
              priority
              recipe={recipes[0]}
              onClick={() => {
                gtag.event({
                  action: TRACKING_EVENTS.recipe_tile_click,
                  category: TRACKING_CATEGORIES.navigation,
                  label: "main_hero_recipe",
                });

                logStatsig(TRACKING_EVENTS.recipe_tile_click, {
                  index: "main_hero_recipe",
                });

                ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
                  index: "main_hero_recipe",
                });
              }}
            />

            {ezoicGate && (
              <Ad
                id="ezoic_141"
                className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
                ezoicId="141"
                screen="MOBILE"
              />
            )}

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              {recipes.slice(1, 5).map((x, i) => (
                <SquareRecipeCard
                  key={`recipe-${x.slug}`}
                  recipe={x}
                  onClick={() => {
                    gridItemClicked(i);
                  }}
                />
              ))}
            </div>
          </div>
        </Section>

        {ezoicGate && (
          <>
            <Ad
              id="ezoic_142"
              className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
              ezoicId="142"
              screen="DESKTOP"
            />
            <Ad
              id="ezoic_142"
              className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
              ezoicId="142"
              screen="MOBILE"
            />
          </>
        )}

        <Section className="py-8">
          <BrandedSection name="Explore More" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={recipes.slice(5, 11)}
            trackingName="home-page"
            cardCategoryColor="text-theme-primary"
            cardTitleFontFace="font-sans"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        {ezoicGate && (
          <Ad
            id="ezoic_108"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="143"
          />
        )}

        <Section className="py-8">
          <BrandedSection name="Trending Now" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-col-1 md:grid-cols-3"
            recipes={recipes.slice(11, 14)}
            trackingName="home-page"
            cardCategoryColor="text-theme-primary"
            cardTitleFontFace="font-sans"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
          ezoicId="144"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
          ezoicId="144"
          screen="MOBILE"
        />

        <Section className="py-8">
          <BrandedSection name="Favorites" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 lg:grid-cols-4"
            recipes={recipes.slice(14, 22)}
            trackingName="home-page"
            cardCategoryColor="text-theme-primary"
            cardTitleFontFace="font-sans"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        {ezoicGate && (
          <Ad
            id="ezoic_146"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="146"
          />
        )}
      </FullWidthElement>
    </div>
  );
}
